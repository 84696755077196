<template>
<Layout :pagetitle="`Job Posting`">

    <div class="row">
      
    <div class="table-responsive">
            <table
              class="
                table
                project-list-table
                table-nowrap
                align-middle
                table-borderless
              "
            >
              <thead>
                <tr>
                  <th scope="col" style="width: 150px">Job Posted</th>
                  <th scope="col" style="width: 80px" class="ps-4"></th>
                  <th scope="col" >Client</th>
                  <th scope="col">Subscription Type</th>

                  <th scope="col">Date Posted</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in jobs" :key="i">
                    <td >
                    <h5 class="text-truncate font-size-16">
                      <a href="javascript: void(0);" class="text-dark"
                        >{{item.title}}</a
                      >
                    </h5>
                    <h6 class="text-truncate mb-0 font-size-16">
                        <a href="javascript: void(0);" class="badge bg-success"
                        > {{item.position.position}}</a
                         >
                       
                    </h6>
                    <p class="text-muted mb-0" >
                        {{item.excerpt}}
                    </p>
                 
                  
                    <ul class="list-inline mb-2">
                      <li class="list-inline-item me-3">
                        <i
                          class="bx bx-calendar  font-size-16 align-middle"
                        ></i>
                        {{formatDate(item.updated_at)}}
                      </li>
                      <li class="list-inline-item me-3 ">
                        <i  class="bx bx-money  font-size-16 align-middle" ></i>
                        {{`${item.salary_rate}/${item.compensation_type.toUpperCase()}`}}
                      </li>
                      
                    </ul>
                    <span v-for="(item, i) in item.employment_type" :key="i" class="  badge badge-pill me-1 font-size-13" 
                            :class="colorBadge(item)">{{item}}</span>
                  </td>
                  
                  <td class="ps-4">
                    <img
                      :src="item.client.image"
                      alt=""
                      class="avatar-md rounded"
                    />
                  </td>
                  <td>
                    <h5 class="text-truncate font-size-14">
                      <a href="javascript: void(0);" class="text-dark"
                        >{{item.client.business}}</a
                      >
                    </h5>
                    <p class="text-muted mb-0">
                        {{item.client.email}}
                    </p>
                  </td>
                  <td>
                    <b-button v-if="item.plan.plan_type ==='free'" class="btn btn-soft-secondary btn-rounded btn-sm font-size-13">
                        <i class="mdi mdi-eye font-size-16"></i>
                            {{`${item.plan.title} (${item.plan.plan_type.toUpperCase()})`}}
                    </b-button>
                    <b-button v-else class="btn btn-soft-primary btn-rounded btn-sm font-size-13">
                        <i class="mdi mdi-eye font-size-16"></i>
                            {{`${item.plan.title} (${item.plan.plan_type.toUpperCase()})`}}
                    </b-button>
                  </td> 
                  <td class="">
                    {{formatDatePosted(item.updated_at)}}
                  </td>
                  
                  
                  
                  <td>
                        <span class=" badge badge-pill me-1 font-size-13" 
                            :class="colorBadge(item.status)">{{item.status.toUpperCase()}}</span>
                    
                  </td>
                  <td>
                    <span class="text-center">
                    <div class="dropdown">
                        <a href="#" class="dropdown-toggle card-drop" data-bs-toggle="dropdown" aria-expanded="false"><i class="mdi mdi-dots-vertical font-size-18"></i></a>
                        <ul class="dropdown-menu dropdown-menu-end" style="">
                            <li v-if="item.status !== 'approved'"><a href="javascript:void(0)" class="dropdown-item" @click="approvePosting(item.id)"><i class="mdi mdi-thumb-up font-size-16 text-success me-1"></i> Approve</a></li>
                            <li v-if="item.status !== 'disapproved'"><a href="javascript:void(0)" class="dropdown-item" @click="disApprovePosting(item.id)"><i class="mdi mdi-thumb-down font-size-16 text-warning me-1"></i> Disapprove</a></li></ul>
                        </div>
                    </span>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
          <div class="col-10">
              <b-pagination
                          class="text-righ"
                          v-model="currentPage"
                          :total-rows="pagination.rows"
                          :per-page="pagination.perPage"
                          :limit="5"
                          @update:modelValue="changePage"
                ></b-pagination>
            </div>
            <div class="col-2 ">
              <div class="row text-right">
              <label class="col-md-6 col-form-label"># of Rows</label>
              <div class="col-md-6">
                <select  v-model="rows" @change="changeRows" class="form-select">
                      <option value="15">15</option>
                      <option value="30">30</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                </select>
              </div>
            </div>
            </div>
            </div>
</Layout>

</template>
<script>
  import Layout from "../../../layouts/main";
  import { useStore } from 'vuex'
  import {ref,computed,reactive,onMounted,watch} from 'vue';
  import { useDebouncedRef } from "../../../../components/utility/debounce";
  import moment from 'moment'
  import Swal from 'sweetalert2'

  export default {
    components:{
        Layout
    },  
    setup() {
      const modalCreate = ref(false)
      const modalEdit = ref(false)
      const search = useDebouncedRef('',400)
      const rows = ref('15')
      const edit = reactive({
        id:null,
        title:''
      })
      const currentPage = ref(1)
     
      const store = useStore()
      const showModalCreate = () =>{
        modalCreate.value = true;
      }
      const showModalEdit = (index) =>{
        Object.assign( edit,{id:index.id,title:index.title});

        modalEdit.value = true;
      }
      function hideModal(){
        modalCreate.value = false;
        modalEdit.value = false;
      }
      const success = computed(()=>{
          return store.getters['jobSettings/success']
      });
      const jobs = computed(()=>{
          return store.getters['jobSettings/getJobs']
      });
      const pagination = computed(()=>{
          return store.getters['jobSettings/getPagination']
      });
      const  spinner =  computed(() =>{ return  store.getters['jobSettings/processingData']})

      const unSubscribeSuccess = () =>{
         store.commit('jobSettings/setSuccess',false)
      }
      function currencyFormat(amount,currency){
        return  amount.toLocaleString('en-US', {
          style: 'currency',
          currency: currency,
        }); 
      }
      function changePage(){
         store.dispatch('jobSettings/getJobs',{page:currentPage.value,search:search.value,rows:rows.value});
      }
      function formatDate(date) {
          return moment(date).fromNow();
      }
      function formatDatePosted(date) {
          return moment(date).format('Y/M/D hh:mm:ss');
      }
      function colorBadge(pm){
            switch(pm){
                case 'part-time':
                    return 'bg-soft-danger text-danger'
                case 'full-time':
                    return 'bg-soft-primary text-primary'
                case 'any':
                    return 'bg-soft-success text-success'
                case 'pending':
                    return 'bg-soft-warning text-warning'
                case 'approved':
                    return 'bg-soft-primary text-primary'
                case 'disapproved':
                    return 'bg-soft-danger text-danger'
                default:
                    return
            }
        }
      function approvePosting(id){
        Swal.fire({
          title: "Are you sure?",
          text: "Make sure you have reviewed the Job Posting!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Yes, Approve it!"
        }).then(result => {
          if (result.value) {
            store.dispatch('jobSettings/editJobs',{id:id,type:'approved'});
            store.dispatch('jobSettings/getJobs',{page:currentPage.value,search:search.value,rows:rows.value});
          }
        });
      }
      function disApprovePosting(id){
        Swal.fire({
          title: "Are you sure?",
          text: "Disapproved Job Post will not be shown on Job Listing",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d9b552",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Yes, Disapproved it!"
        }).then(result => {
          if (result.value) {
            store.dispatch('jobSettings/editJobs',{id:id,type:'disapproved'});
            store.dispatch('jobSettings/getJobs',{page:currentPage.value,search:search.value,rows:rows.value});
          }
        });
      }
      function changeRows(){
          store.dispatch('jobSettings/getJobs',{page:1,search:search.value,rows:rows.value});
      }
      onMounted(() => {
           store.dispatch('jobSettings/getJobs',{page:1,search:'',rows:rows.value});
      })
      watch(search , newSearch=>{
          store.dispatch('jobSettings/getJobs',{page:1,search:newSearch,rows:rows.value});
      })
     
      return {
        rows,
        colorBadge,
        changeRows,
        currencyFormat,
        showModalCreate,
        showModalEdit,
        formatDate,
        edit,
        approvePosting,
        disApprovePosting,
        search,
        changePage,
        unSubscribeSuccess,
        success,
        modalCreate,
        modalEdit,
        hideModal,
        jobs,
        spinner,
        pagination,
        currentPage,
        formatDatePosted
      };
    }
  };
  </script>